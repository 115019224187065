import React, {useContext, useEffect, useState, useMemo} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft, faAngleRight} from "@fortawesome/free-solid-svg-icons";
import {DoorContext} from "controllers/DoorController";
import {DropdownMenuStructure, MenuContext} from "../index";
import DropdownMenu from "./DropdownMenu";

export default function DropdownMenuContentGlassOpacity() {
    const {doorParameters, setDoorParameters} = useContext(DoorContext);
    const {isMenuOpen, setMenuOpen} = useContext(MenuContext);

    const [isNextButtonVisible, setIsNextButtonVisible] = useState(true);
    const [isPrevButtonVisible, setIsPrevButtonVisible] = useState(true);

    const [glassOpacitiesOnDisplay, setGlassOpacitiesOnDisplay] = useState([]);

    const generateGlassOpacityOnDisplay = useMemo(() => {
        return (targetGlassOpacityId, indexIncrement = 0) => {
            let glassOpacities = [];

            let index = 0;
            let targetFound = false;

            while (glassOpacities.length !== 3 && glassOpacities.length < doorParameters.glassOpacities.length) {
                let currentIndex = index % doorParameters.glassOpacities.length;

                if (doorParameters.glassOpacities[currentIndex].id === targetGlassOpacityId && !targetFound) {
                    targetFound = true;
                    index += indexIncrement;

                    if (index < 0) {
                        index = doorParameters.glassOpacities.length - 1;
                    }

                    if (indexIncrement === 0) {
                        if (currentIndex < 3) index = 0;
                        else index -= 2;
                    }

                    currentIndex = index % doorParameters.glassOpacities.length;
                }

                if (targetFound) {
                    glassOpacities.push(doorParameters.glassOpacities[currentIndex]);
                }

                index++;
            }

            setGlassOpacitiesOnDisplay(glassOpacities);
        }
    }, [])

    useEffect(() => {
        generateGlassOpacityOnDisplay(doorParameters.getActiveGlassOpacityOption().id);
    }, []);

    useEffect(() => {
        if (glassOpacitiesOnDisplay.length === 0) return;

        setIsNextButtonVisible(glassOpacitiesOnDisplay.slice(-1)[0].id !== doorParameters.glassOpacities.slice(-1)[0].id);
        setIsPrevButtonVisible(glassOpacitiesOnDisplay[0].id !== doorParameters.glassOpacities[0].id);
    }, [glassOpacitiesOnDisplay]);

    return (
        <>
            <DropdownMenu
                title={'glass Opacity'}
                contentHeight={'140px'}
                isMenuOpen={isMenuOpen.glassOpacity}
                setMenuOpen={() => {
                    let dropdownMenuStructure = new DropdownMenuStructure();
                    dropdownMenuStructure.glassOpacity = !isMenuOpen.glassOpacity;
                    setMenuOpen(dropdownMenuStructure);
                }}
            >
                <div className={'h-[140px] bg-slidingdoorco-menu-content-500 flex'}>
                    <div className={'w-[32px] flex justify-center px-2'}>
                        <button
                            className={'flex flex-col justify-center z-10 ' + (isPrevButtonVisible ? '' : 'hidden')}
                            onClick={() => {
                                generateGlassOpacityOnDisplay(glassOpacitiesOnDisplay[0].id, -1);
                            }}
                        >
                            <FontAwesomeIcon icon={faAngleLeft}
                                             className={'h-8 text-slidingdoorco-menu-content-200 hover:text-black cursor-pointer'}/>
                        </button>
                    </div>

                    <div className={'flex w-full overflow-hidden gap-2 py-2'}>
                        {
                            glassOpacitiesOnDisplay.map((glassOpacity) => {
                                return (
                                    <React.Fragment key={glassOpacity.id}>
                                        <button
                                            className={'flex flex-col justify-start w-1/3'}
                                            onClick={() => {
                                                let clone = doorParameters.createClone();
                                                clone.setActiveGlassOpacityOption(glassOpacity.id);
                                                setDoorParameters(clone);
                                            }}
                                        >
                                            <img
                                                src={glassOpacity.icon}
                                                className={'mx-auto rounded-sm min-h-[90px] max-h-[90px] border-2 border-white'}
                                                alt={glassOpacity.id}
                                            />
                                            <div className={
                                                'text-center text-white text-sm w-full ' +
                                                (
                                                    doorParameters.getActiveGlassOpacityOption().id === glassOpacity.id ?
                                                        'font-bold' : ''
                                                )
                                            }>
                                                {glassOpacity.name}
                                            </div>
                                        </button>
                                    </React.Fragment>
                                );
                            })
                        }
                    </div>

                    <div className={'w-[32px] flex justify-center px-2'}>
                        <button
                            className={'flex flex-col justify-center z-10 ' + (isNextButtonVisible ? '' : 'hidden')}
                            onClick={() => {
                                generateGlassOpacityOnDisplay(glassOpacitiesOnDisplay[0].id, 1);
                            }}
                        >
                            <FontAwesomeIcon icon={faAngleRight}
                                             className={'h-8 text-slidingdoorco-menu-content-200 hover:text-black cursor-pointer'}/>
                        </button>
                    </div>
                </div>
            </DropdownMenu>
        </>
    );
}